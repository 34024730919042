// Vendors
import React from "react";
import styled from "styled-components";

//
import Layout from '../layout/index.js';
import Section from 'components/section';

const Title = styled.h1``;

export default (props) => (
	<Layout {...props}>
		<Section>
			<Title>404 - Not found</Title>
		</Section>
	</Layout>
);
